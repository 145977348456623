import { Box } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import AlertItem from './AlertItem';

interface Props {
  alerterItems?: IAlerterItem[],
  autoClose?: boolean,
}

const Alerter: FC<Props> = ({
  alerterItems, autoClose,
}: Props): ReactElement => (
  <Box>
    {alerterItems.map((item) => <AlertItem {...item} />)}
  </Box>
);

Alerter.defaultProps = {
  alerterItems: undefined,
  autoClose: false,
};

export default Alerter;
